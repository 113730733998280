
"use strict"

import scrollEl from './modules/scrollEl'
import showHideEl from './modules/showHideEl'
import mediabox from 'mediabox'

scrollEl.init()
showHideEl.init()

mediabox('.mediabox')