
function init() {

	const elements = [
		...document.querySelectorAll('[data-show]'), 
		...document.querySelectorAll('[data-hide]')
	]

	elements.forEach(el => {

		const show = el.getAttribute('data-show')
		const hide = el.getAttribute('data-hide')

		const showEls = show ? [...document.querySelectorAll(show)] : [];
		const hideEls = hide ? [...document.querySelectorAll(hide)] : [];

		(showEls.length || hideEls.length) && el.addEventListener('click', event => {
			event.preventDefault()
			showEls.forEach(el => el.style.display = 'block');
			hideEls.forEach(el => el.style.display = 'none');
		})
	})
}

export default {
	init
}
