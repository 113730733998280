
function init() {
	const buttons = document.querySelectorAll('[data-scroll]')

	;[...buttons].forEach(button => {
		const selector = button.getAttribute('data-scroll')
		const el = document.querySelector(selector)
		el && button.addEventListener('click', event => {
			event.preventDefault()
			window.scrollTo(
				el.offsetLeft, 
				el.offsetTop
			)
		})
	})
}

export default {
	init
}
